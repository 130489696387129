<template>
  <div class="col text-center">
    <a href="/">
      <img class="banner-top" alt="gpib kasih karunia badung" :src="'/primary_heroes.png'">
    </a>
  </div>
  <!-- <nav id="topNav" class="navbar navbar-dark shadow-sm navbar-expand-lg bg-success sticky-top border-success">-->
  <nav id="topNav" class="navbar navbar-dark shadow-sm navbar-expand-lg bg-kalem sticky-top py-0">
    <div class="container">
      <a class="navbar-brand" href="/">
        <img class="rounded-circle p-img-show" :src="'/gpib.png'">
      </a>
      <button class="navbar-toggler ms-auto m-2 btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
        aria-controls="offcanvasNavbar">
        MENU <span class="navbar-toggler-icon"></span>
      </button>
      <div class="offcanvas offcanvas-end bg-kalem text-white" tabindex="-1" id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasNavbarLabel">GPIB Kasih Karunia Badung-Bali</h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <ul class="navbar-nav mx-auto">
            <li class="nav-item">
              <a href="/" class="nav-link"><span class="link-text">BERANDA</span></a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="dropOne" role="button" data-bs-toggle="dropdown"
                aria-expanded="true">
                <span class="link-text">INFORMASI</span>
              </a>
              <ul class="dropdown-menu shadow" style="width:20rem;" aria-labelledby="dropOne">
                <li><a class="dropdown-item text-wrap" href="/daftar_jemaat_baru"> <span class="link-text2"><i
                        class='fas fa-file-alt'></i>&nbsp;Jemaat Baru</span></a></li>
                <li><a class="dropdown-item text-wrap" href="/daftar_baptisan"> <span class="link-text2"><i
                        class='fas fa-file-alt'></i>&nbsp;Baptisan</span></a></li>
                <li><a class="dropdown-item text-wrap" href="/katekisasi"> <span class="link-text2"><i
                        class='fas fa-file-alt'></i>&nbsp;Informasi Katekisasi</span></a></li>
                <li><a class="dropdown-item text-wrap" href="/pelayanan_perkawinan"> <span class="link-text2"><i
                        class='fas fa-file-alt'></i>&nbsp;Pemberkatan Perkawinan</span></a></li>
                <li><a class="dropdown-item text-wrap" href="/kontak_korsek"> <span class="link-text2"><i
                        class='fas fa-file-alt'></i>&nbsp;Kontak Korsek</span></a></li>
                <!-- <li><a class="dropdown-item text-wrap" href="#"> <span class="link-text2"><i
                        class='fas fa-file-alt'></i>&nbsp;Permohonan Atestasi (Pindah Gereja)</span></a></li> -->
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="dropOne" role="button" data-bs-toggle="dropdown"
                aria-expanded="true">
                <span class="link-text">SEJARAH</span>
              </a>
              <ul class="dropdown-menu shadow" style="width:20rem;" aria-labelledby="dropOne">
                <li><a class="dropdown-item text-wrap" href="/visi-misi"><span class="link-text2"><i
                        class="fas fa-angle-right"></i> VISI MISI GPIB</span></a></li>
                <li><a class="dropdown-item text-wrap" href="/kmj"><span class="link-text2"><i
                        class="fas fa-angle-right"></i> KEPEMIMPINAN KMJ</span></a></li>
                <li><a class="dropdown-item text-wrap" href="/phmj"><span class="link-text2"><i
                        class="fas fa-angle-right"></i> PHMJ</span></a></li>
              </ul>
            </li>
            <!--
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="dropOne" role="button" data-bs-toggle="dropdown"
                aria-expanded="true">
                <span class="link-text">FORM ADMINISTRASI</span>
              </a>
              <ul class="dropdown-menu shadow" style="width:20rem;" aria-labelledby="dropOne">
                <li><a class="dropdown-item text-wrap" href="#"> <span class="link-text2"><i
                        class='fas fa-file-alt'></i>&nbsp;Daftar Jemaat Baru</span></a></li>
                <li><a class="dropdown-item text-wrap" href="#"> <span class="link-text2"><i
                        class='fas fa-file-alt'></i>&nbsp;Daftar Baptisan</span></a></li>
                <li><a class="dropdown-item text-wrap" href="#"> <span class="link-text2"><i
                        class='fas fa-file-alt'></i>&nbsp;Informasi Katekisasi</span></a></li>
                <li><a class="dropdown-item text-wrap" href="#"> <span class="link-text2"><i
                        class='fas fa-file-alt'></i>&nbsp;Daftar Pelayanan Pemberkatan Perkawinan</span></a></li>
                <li><a class="dropdown-item text-wrap" href="#"> <span class="link-text2"><i
                        class='fas fa-file-alt'></i>&nbsp;Permohonan Atestasi (Pindah Gereja)</span></a></li>
              </ul>
            </li> -->
            <!-- <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="dropOne" role="button" data-bs-toggle="dropdown"
                aria-expanded="true">
                <span class="link-text">ABSENSI IBADAH PELKAT</span>
              </a>
              <ul class="dropdown-menu shadow" style="width:20rem;" aria-labelledby="dropOne">
                <li><a class="dropdown-item text-wrap" href="/absensi/pa"> <span class="link-text2"><i
                        class='fas fa-file-alt'></i>&nbsp;PA</span></a></li>
                <li><a class="dropdown-item text-wrap" href="/absensi/pt"> <span class="link-text2"><i
                        class='fas fa-file-alt'></i>&nbsp;PT</span></a></li>
                <li><a class="dropdown-item text-wrap" href="/absensi/gp"> <span class="link-text2"><i
                        class='fas fa-file-alt'></i>&nbsp;GP</span></a></li>
                <li><a class="dropdown-item text-wrap" href="/absensi/pkp"> <span class="link-text2"><i
                        class='fas fa-file-alt'></i>&nbsp;PKP</span></a></li>
                <li><a class="dropdown-item text-wrap" href="/absensi/pkb"> <span class="link-text2"><i
                        class='fas fa-file-alt'></i>&nbsp;PKB</span></a></li>
                <li><a class="dropdown-item text-wrap" href="/absensi/pklu"> <span class="link-text2"><i
                        class='fas fa-file-alt'></i>&nbsp;PKLU</span></a></li>
              </ul>
            </li> -->
            <li class="nav-item">
              <a class="nav-link" href="/warta_jemaat"><span class="link-text">WARTA JEMAAT</span></a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" href="/berita_duka"><span class="link-text">BERITA DUKA
                </span></a>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import endpoints from '@/services/endpoints';
export default {
  //data function
  name: 'header_client',
  data() {
    return {
      allNavMenu: null,
      navMenuLoaded: false
    }
  },
  methods: {
    getAllNavMenu() {
      this.navMenuLoaded = false
      endpoints.getAllNavMenu().then(res => {
        var master = res.data.data
        this.allNavMenu = master
        this.navMenuLoaded = true
      }).catch(e => {
        console.log(e)
        this.navMenuLoaded = true
      })
    },
    // pelkatGoTo(slug) {
    //   this.$router.push('/pelkat/' + slug)
    // },
    // presbiterGoTo(slug) {
    //   this.$router.push('/presbiter/' + slug)
    // },
    // komisiGoTo(slug) {
    //   this.$router.push('/komisi/' + slug)
    // }
  },
  mounted() {
    // this.getAllNavMenu()
  }
}
</script>
  
<style scoped>
.btn {
  font-size: initial;
}

.dropdown-menu {
  max-height: 280px;
  overflow-y: auto;
}
</style>