<template>
  <footer class="footer-uhuy" style="color:white;">
    <div class="container">
      <div class="row mb-2">
        <div class="col-sm-4">
          <h6>PENDETA</h6>
          <hr />
          <template v-if="!pendetaLoaded">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </template>
          <template v-else>
            <template v-for="data in listPendeta" :key="data.id">
              <p>
                {{data.name}}<br />
                <b class="text-uppercase">[{{data.jabatan}}]</b>
              </p>
            </template>
          </template>
          <hr />
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col">
                  <a class="about-us" href="https://www.youtube.com/@gpibkasihkaruniabadung3117" target="_blank"><font-awesome-icon :icon="['fab', 'youtube']" />&nbsp;YOUTUBE GPIB KASIH KARUNIA BADUNG - BALI</a>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <a class="about-us" href="/warta_jemaat" target="_blank"><font-awesome-icon :icon="['fas', 'rectangle-list']" />&nbsp;WARTA JEMAAT</a>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <a class="about-us" href="/berita_duka" target="_blank"><font-awesome-icon :icon="['fas', 'cross']" />&nbsp;BERITA DUKA</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <h6>HUBUNGI KAMI</h6>
          <hr />
          <!-- <div class="row">
            <div class="col">
              <a class="about-us" href="#" target="_blank"><i class="fab fa-facebook-f"></i>&nbsp;Facebook</a>
            </div>
          </div> -->
          <div class="row">
            <div class="col">
              <a class="about-us" href="https://www.instagram.com/inforkom.kakaba/" target="_blank"><font-awesome-icon :icon="['fab', 'instagram']" />&nbsp;INSTAGRAM</a>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <a class="about-us"
                href="https://wa.me/6282247231915?text=Halo%20saya%20ingin%20mencari%20informasi"
                target="_blank"><font-awesome-icon :icon="['fab', 'whatsapp']" />&nbsp;WHATSAPP (+6282247231915)</a>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <a class="about-us"
                href="tel:6282247231915"
                target="_blank"><font-awesome-icon :icon="['fas', 'phone']" />&nbsp;PHONE (+6282247231915)</a>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col">
              <a class="about-us"
                href="mailto:gpibkakadenbali@gmail.com"
                target="_blank"><font-awesome-icon :icon="['fas', 'envelope']" />&nbsp;gpibkakadenbali@gmail.com</a>
            </div>
          </div> -->
          <div class="row">
            <div class="col">
              <a class="about-us"
                href="mailto:kasihkarunia.denpasar@gpib.or.id"
                target="_blank"><font-awesome-icon :icon="['fas', 'envelope']" />&nbsp;EMAIL (kasihkarunia.denpasar@gpib.or.id)</a>
            </div>
          </div>
          <hr />
          <div class="row mt-2">
            <div class="col">
              <div class="row">
                <div class="col">
                  <a class="about-us"
                    href="https://a1.siar.us/public/gpibvoice"
                    target="_blank"><font-awesome-icon :icon="['fas', 'broadcast-tower']" />&nbsp;SIARAN RADIO GPIB</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <h6>ALAMAT</h6>
          <hr>
          <h6>Gedung Yayasan Panji Kasih Karunia:</h6>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d246.55188534008838!2d115.17892929019348!3d-8.612298817092094!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd2397b04463cfb%3A0x16454087b97aefba!2sYayasan%20Panji%20Kasih%20Karunia!5e0!3m2!1sid!2sid!4v1702034573880!5m2!1sid!2sid" width="100%" height="auto" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          <p>Jl. Panji Kwanji, Kec. Kuta Utara, Kabupaten Badung, Bali 80361</p>
        </div>
      </div>
      <hr>
      <div class="row text-white">
        <div class="col-sm-12 mx-auto text-end">
          <p style="font-size: 0.8rem;">2024 Copyright &copy; KOMISI INFORKOM GPIB KASIH KARUNIA BADUNG - BALI</p>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import endpoints from '@/services/endpoints';
export default {
  name: 'footer_client',
  data() {
    return {
      listPendeta: null,
      pendetaLoaded: false
    }
  },
  methods: {
    getPendeta() {
      this.pendetaLoaded = false
      endpoints.getPendeta().then(res => {
        var master = res.data.data
        this.listPendeta = master
        this.pendetaLoaded = true
      }).catch(e => {
        console.log(e)
        this.pendetaLoaded = true
      })
    }
  },
  mounted() {
    this.getPendeta()
  }
}
</script>
