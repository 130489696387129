import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import interceptorService from './services/interceptor'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import moment from 'moment'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/src/jquery.js'
import 'bootstrap/dist/js/bootstrap.min.js'

/* add icons to the library */
library.add(fab)
library.add(fas)
library.add(far)

// interceptorService(store)

// createApp(App).mount('#app')
const app = createApp(App)
app.use(router)
app.use(store)
app.mount('#app')
app.component('font-awesome-icon', FontAwesomeIcon)

// Mixins utk global functions
app.mixin({
    methods: {
      uangIndonesia: function (value) {
        if (typeof value !== 'number') {
          return value
        }
        var formatter = new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        })
        return formatter.format(value)
      },
      shortDesc: function (text) {
        if (text == null) {
          return 'N/A'
        } else {
          var text_length = text.length
          if (text_length > 40) {
            var result = text.substr(0, 40) + '...'
            return result
          } else {
            return text
          }
        }
      },
      formatProperDate(date) {
        return moment(date).utc(true).format('YYYY-MM-DD HH:mm:ss')
      },
      checkData(data) {
        if (data == null) {
          return '-'
        } else if (data == '') {
          return '-'
        } else if (data == 0) {
          return 0
        } else if (Array.isArray(data)) {
          if (data.length < 0) {
            return []
          }
        } else {
          return data
        }
      },
    },
  })

