// import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import layout_client from '@/components/layout_client'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: layout_client,
    redirect: '/',
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
      },
      // Pelkat
      {
        path: '/pelkat/:id',
        name: 'Pelkat',
        component: () => import('@/views/Pelkat/Pelkat.vue'),
      },
      // Komisi
      {
        path: '/komisi/:id',
        name: 'Komisi',
        component: () => import('@/views/Komisi/Komisi.vue'),
      },
      // Presbiter
      {
        path: '/presbiter/:id',
        name: 'Presbiter',
        component: () => import('@/views/Presbiter/Presbiter.vue'),
      },
      // KMJ
      {
        path: '/kmj',
        name: 'KMJ',
        component: () => import('@/views/Kmj.vue'),
      },
      // Visi Misi
      {
        path: '/visi-misi',
        name: 'Visi Misi',
        component: () => import('@/views/VisiMisi.vue'),
      },
      // Dokumen
      {
        path: '/dokumen',
        name: 'Dokumen',
        component: () => import('@/views/Dokumen.vue'),
      },
      {
        path: '/daftar_jemaat_baru',
        name: 'Daftar Jemaat Baru',
        component: () => import('@/views/Pelayanan/JemaatBaru.vue'),
      },
      {
        path: '/daftar_baptisan',
        name: 'Daftar Baptisan',
        component: () => import('@/views/Pelayanan/Baptisan.vue'),
      },
      {
        path: '/katekisasi',
        name: 'Daftar Katekisasi',
        component: () => import('@/views/Pelayanan/Katekisasi.vue'),
      },
      {
        path: '/pelayanan_perkawinan',
        name: 'Daftar Pelayanan Perkawinan',
        component: () => import('@/views/Pelayanan/Perkawinan.vue'),
      },
      {
        path: '/kontak_korsek',
        name: 'Kontak Korsek',
        component: () => import('@/views/Pelayanan/KontakKorsek.vue'),
      },
      // // Sejarah
      // {
      //   path: '/sejarah',
      //   name: 'Sejarah',
      //   component: () => import('@/views/Sejarah.vue'),
      // },
      // PHMJ
      {
        path: '/phmj',
        name: 'PHMJ',
        component: () => import('@/views/Phmj.vue'),
      },
      // Berita Duka
      {
        path: '/berita_duka',
        name: 'Berita Duka',
        component: () => import('@/views/BeritaDuka/All.vue'),
      },
      {
        path: '/berita_duka/:id',
        name: 'Rincian Berita Duka',
        component: () => import('@/views/BeritaDuka/Detail.vue'),
      },
      // Warta Jemaat
      {
        path: '/warta_jemaat',
        name: 'Warta Jemaat',
        component: () => import('@/views/WartaJemaat/All.vue'),
      },
      {
        path: '/warta_jemaat/:id',
        name: 'Rincian Warta Jemaat',
        component: () => import('@/views/WartaJemaat/Detail.vue'),
      },
      // Cek Iuran Makkam
      // {
      //   path: '/cek_iuran_makam',
      //   name: 'Cek Iuran Makam',
      //   component: () => import('@/views/CekIuranMakam.vue'),
      // },
      // Absensi
      {
        path: '/absensi/:pelkat',
        name: 'Absensi Pelkat',
        component: () => import('@/views/Absensi.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login']
//   const authRequired = !publicPages.includes(to.path)
//   const loggedIn = localStorage.getItem('user')
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next('/login')
//   } else {
//     next()
//   }
// })

export default router